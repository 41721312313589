/** @jsx jsx */
import React, {useEffect, useState} from "react";
import {Link, navigate} from "gatsby";
import Layout from "@coursemaker/gatsby-theme-coursemaker/src/components/layout";
import FastAPIClient from "../../../../auth/fast_api_auth";
import {jsx} from "theme-ui";
import {NumberParam, useQueryParam} from "use-query-params";
import { toast } from 'react-toastify'
import {coursesFromJWT} from "../auth/auth";


const PaymentSuccess = () => {
  let client = new FastAPIClient();

    const [paymentState, setPaymentState] = useState('processing')

    const [courseID, setCourseID] = useQueryParam("course_id", NumberParam);

    const coursesBefore = coursesFromJWT()

    const updateAuthorization = async (attemptsLeft) => {
        console.log("Refreshing auth token...")
        let result = await client.updateAuthorization()
        if (!result) {
            setPaymentState('failed')
            return
        }
        let coursesAfter = coursesFromJWT()
        if (
            coursesAfter.includes(courseID)
            || coursesBefore.length < coursesAfter.length
        ) {
            console.log("Success: Increase in courses authorised detected...")
            toast.dismiss()
            toast.success("Success: Payment Complete")
            setPaymentState('completed')
            return
        }
        if (attemptsLeft <= 0) {
            console.error("Unable to detect any increase in courses authorised after refreshing the auth token 20 times.")
            toast.error("There was an error during the payment process, please try logging out and in again. If the problem continues" +
                "please contact the school owner", {delay: 10000});
            setPaymentState('failed')
            return
        }
        setTimeout(() => {
            updateAuthorization(attemptsLeft - 1)
        }, 1500)
    }
    useEffect(() => {
        // Keep checking to see if authorisation token either:
        // a) Contains the course id provided in the optional course_id query param
        // b) The total number of courses in the authorisation token increases
        updateAuthorization(20)
    }, [])

    const pageContext = {
        pageTitle: paymentState === 'processing' ? 'Payment Processing...' : 'Payment Success',
        school: {
            name: '',
            subtitle: ''
        }
    }

  return (
    <Layout pageContext={pageContext}>
      <div className="container" align="center">
          {
              paymentState === 'processing' &&
              <>
                  <h1>Payment Processing, please wait...</h1>
              </>
          }
          {
              paymentState === 'completed' &&
              <>
                  <h1>Payment Success</h1>
                  <Link
                      to="/courses"
                      sx={{
                          color: `primary_blue`,
                      }}
                  >Go to the courses
                  </Link>
              </>
          }
          {
              paymentState === 'failed' &&
              <>
                  <h1>Unknown Error</h1>
                  <Link
                      to="/login"
                      sx={{
                          color: `primary_blue`,
                      }}
                  >Logout
                  </Link>
              </>
          }
      </div>
    </Layout>
  );
};

export default PaymentSuccess;
